import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';
import {
  DATA_TYPE_EVENTS,
  DATA_TYPE_CONTACTED_PARTICIPANTS,
  DATA_TYPE_MEETING_CONTACTS,
  DATA_TYPE_USERPROJECTS,
} from 'app-customs/config/dataConfig';

import UserDataForm from './UserDataForm';
import ContactedParticipants from './ContactedParticipants';
import MeetingContacts from './MeetingContacts';
import Ebadge from './EBadge';
import Tabs from 'src/components/tabs/Tabs';
import List from 'src/components/list/List';
import config from 'app-customs/config/config';

import STATUS from 'src/store/fetchStatuses';
import { renderList } from 'src/components/list/listUtil';

import { openUrl } from 'src/core/util/JsTools';
import Contacts from 'src/pages/user-data/Contacts';
import FilterDialog from 'src/components/filter-dialog/FilterDialog';

import { getCurrent } from 'src/core/Lang';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

// const LOG_PREF = '[UserDataPageContent] ';

export const PROFILE_TAB_KEY = 'yourProfile';
export const EVENTS_TAB_KEY = 'yourEvents';
export const MET_CONTACTS_TAB_KEY = 'metContacts';
export const MEETING_CONTACTS_TAB_KEY = 'meetingContacts';
export const CONTACTED_PARTICIPANTS_TAB_KEY = 'contactedParticipants';
export const E_BADGE_TAB_KEY = 'eBadge';
export const PROJECT_VISITOR_TAB_KEY = 'projetVisiteur';

class UserDataContent extends Component {
  state = {};
  tabIndexMapping = [];
  getTabKey() {
    return this.tabIndexMapping[this.props.tabIndex] || this.props.tabKey;
  }
  renderTabContent() {
    switch (this.getTabKey()) {
      case PROFILE_TAB_KEY:
        return (
          <UserDataForm
            status={this.props.formStatus}
            sections={this.props.formSections}
            userData={this.props.userData}
            tosLink={this.props.tosLink}
            externalResourceLinkOpenInInAppBrowser={
              this.props.externalResourceLinkOpenInInAppBrowser
            }
            tosLinkOpenInInAppBrowser={this.props.tosLinkOpenInInAppBrowser}
            externalResourceLinkTarget={this.props.externalResourceLinkTarget}
            logout={this.props.logout}
            keyboardHeight={this.props.keyboardHeight}
            labels={this.props.labels}
            actions={this.props.actions}
          />
        );

      case E_BADGE_TAB_KEY:
        /*  return <Ebadge userData={this.props.userData} labels={this.props.labels} />; */
        return null;

      case MEETING_CONTACTS_TAB_KEY:
        return (
          <MeetingContacts
            actions={this.props.actions}
            labels={this.props.labels}
            meetingContacts={this.props.userData.meetingContacts}
            searchResults={this.props.searchResults}
            keyboardHeight={this.props.keyboardHeight}
          />
        );

      case EVENTS_TAB_KEY:
        return (
          <List
            items={this.props.userData.events}
            dataType={DATA_TYPE_EVENTS}
            actions={this.props.actions}
            labels={this.props.labels}
            displayFavorites={false}
            pageKey={USER_DATA_PAGE_KEY}
          />
        );

      case MET_CONTACTS_TAB_KEY:
        return (
          <Contacts
            actions={this.props.actions}
            labels={this.props.labels}
            contacts={this.props.contacts}
            searchResults={this.props.searchResults}
          />
        );

      case CONTACTED_PARTICIPANTS_TAB_KEY:
        return (
          <ContactedParticipants
            actions={this.props.actions}
            labels={this.props.labels}
            participants={this.props.userData.contactedParticipants}
            searchResults={this.props.searchResults}
            keyboardHeight={this.props.keyboardHeight}
          />
        );

      case PROJECT_VISITOR_TAB_KEY:
        return this.props.isFilterVisible ? (
          <FilterDialog />
        ) : (
          <div
            style={{
              height: (document.documentElement.clientHeight / 100) * 80,
              overflow: 'scroll',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: 'auto',
                minwidth: 'auto',
                marginTop: 20,
                marginBottom: 20,
                marginRight: '.6em',
                boxSizing: 'border-box',
                borderBottom: '1px solid #eee',
                cursor: 'pointer',
              }}
            >
              <span
                className="app-toolbar-icon"
                onClick={this.handleFilterButtonClick}
                title={this.props.labels.common.goToFilter}
              >
                <GenericIcon
                  className={'filter-icon' + (this.props.isDataFiltered ? ' filter-enabled' : '')}
                  style={{ fontSize: '.8em' }}
                />
              </span>
            </div>
            {renderList({
              items: this.props.userProjectsItems !== undefined ? this.props.userProjectsItems : {},
              status: STATUS.FETCHED,
              favIconDisabled: false,
              actions: this.props.actions,
              labels: this.props.labels,
              pageKey: USER_DATA_PAGE_KEY,
              listHeight: (document.documentElement.clientHeight / 100) * 70,
            })}
          </div>
        );

      default:
        return null;
    }
  }

  handleFilterButtonClick = () => {
    this.props.actions.showFilterDialog([DATA_TYPE_USERPROJECTS]);
  };

  getContactsCount = () => (Array.isArray(this.props.contacts) ? this.props.contacts.length : 0);

  getEventsCount() {
    return Array.isArray(this.props.userData.events) ? this.props.userData.events.length : 0;
  }

  getContactedParticipantsCount() {
    return Array.isArray(this.props.userData.contactedParticipants)
      ? this.props.userData.contactedParticipants.length
      : 0;
  }

  getMeetingContactesCount() {
    return Array.isArray(this.props.userData.meetingContacts)
      ? this.props.userData.meetingContacts.length
      : 0;
  }

  getEbadgeLink() {
    return this.props.userData.externalResources
      ? this.props.userData.externalResources.badge
      : false;
  }

  hasProjectVisitorItems() {
    return (
      this.props.userProjectsItems !== null &&
      typeof this.props.userProjectsItems === 'object' &&
      this.props.userProjectsItems[DATA_TYPE_USERPROJECTS] &&
      this.props.userData.type &&
      this.props.userData.type === 'Exposant' // Visiteur or Exposant
    );
  }

  profileTabData = {
    key: PROFILE_TAB_KEY,
    isCurrentTab: () => this.getTabKey() === PROFILE_TAB_KEY,
    getTabContent: () => this.props.labels.userData.yourProfile,
  };

  eventTabData = {
    key: EVENTS_TAB_KEY,
    isCurrentTab: () => this.getTabKey() === EVENTS_TAB_KEY,
    isClickable: () => this.getEventsCount() > 0,
    getTabContent: () => this.props.labels.userData.yourEvents,
  };

  metContactsTabData = {
    key: MET_CONTACTS_TAB_KEY,
    isCurrentTab: (key) => this.getTabKey() === MET_CONTACTS_TAB_KEY,
    isClickable: () => this.getContactsCount() > 0,
    getTabContent: () => this.props.labels.userData.metContacts,
  };

  contactedParticipantsTabData = {
    key: CONTACTED_PARTICIPANTS_TAB_KEY,
    isCurrentTab: () => this.getTabKey() === CONTACTED_PARTICIPANTS_TAB_KEY,
    isClickable: () => this.getContactedParticipantsCount() > 0,
    getTabContent: () => this.props.labels.data[DATA_TYPE_CONTACTED_PARTICIPANTS].plural,
  };

  meetingContactsTabData = {
    key: MEETING_CONTACTS_TAB_KEY,
    isCurrentTab: () => this.getTabKey() === MEETING_CONTACTS_TAB_KEY,
    isClickable: () => this.getMeetingContactesCount() > 0,
    getTabContent: () => this.props.labels.data[DATA_TYPE_MEETING_CONTACTS].plural,
  };

  eBadgeTabData = {
    key: E_BADGE_TAB_KEY,
    isCurrentTab: () => this.getTabKey() === E_BADGE_TAB_KEY,
    isClickable: () => this.getEbadgeLink(),
    getTabContent: () => this.props.labels.userData.badge,
  };

  projectVisitorTabData = {
    key: PROJECT_VISITOR_TAB_KEY,
    isCurrentTab: () => this.getTabKey() === PROJECT_VISITOR_TAB_KEY,
    isClickable: () => this.hasProjectVisitorItems(),
    getTabContent: () => this.props.labels.userData.userProjects,
  };

  onTabClick = (tabIndex) => {
    const extRes = this.props.userData.externalResources;
    const url = extRes.badge[getCurrent()] || extRes.badge;
    if (this.tabIndexMapping[tabIndex] === E_BADGE_TAB_KEY) {
      if (openUrl(url, null, config.BADGE_LINK_TARGET, config.BADGE_LINK_OPENININAPPBROWSER)) {
        this.props.actions.linkClicked(url);
      }
    } else {
      this.props.actions.navigate(USER_DATA_PAGE_KEY, {
        tabIndex,
      });
    }
    // this.props.actions.userDataTabIndexUpdate(tabIndex);
  };

  render() {
    let hasContactsFeature = true;
    if (config.CONTACTS.FEATURE_ENABLED !== true || !global.isCordovaContext) {
      hasContactsFeature = false;
    }

    if (Array.isArray(config.CONTACTS.RESTRICTIONS)) {
      let restriction = config.CONTACTS.RESTRICTIONS.find(
        (restr) => restr.pageKey === USER_DATA_PAGE_KEY
      );
      if (restriction) {
        // A restriction has been defined for this page
        if (restriction.for.indexOf(this.props.profile) !== -1) {
          // Restriction applies for this profile, so skip rendering share button
          hasContactsFeature = false;
        }
      }
    }

    let hasEvents = this.getEventsCount() > 0,
      hasContacts = this.getContactsCount() > 0 && hasContactsFeature,
      hasContactedParticipants = this.getContactedParticipantsCount() > 0,
      hasEbadge = this.getEbadgeLink(),
      hasMeetingContacts = this.getMeetingContactesCount() > 0,
      hasProjectVisitor = this.hasProjectVisitorItems();

    let tabsData = [this.profileTabData];
    this.tabIndexMapping = [PROFILE_TAB_KEY];
    if (hasEbadge) {
      tabsData.push(this.eBadgeTabData);
      this.tabIndexMapping.push(E_BADGE_TAB_KEY);
    }
    if (hasMeetingContacts) {
      tabsData.push(this.meetingContactsTabData);
      this.tabIndexMapping.push(MEETING_CONTACTS_TAB_KEY);
    }
    if (hasProjectVisitor) {
      tabsData.push(this.projectVisitorTabData);
      this.tabIndexMapping.push(PROJECT_VISITOR_TAB_KEY);
    }
    if (hasEvents) {
      tabsData.push(this.eventTabData);
      this.tabIndexMapping.push(EVENTS_TAB_KEY);
    }
    if (hasContacts) {
      if (
        config.CONTACTS.SAVE_CONTACT_TO_DEVICE.FEATURE_ENABLED === true &&
        global.isCordovaContext
      ) {
        let hasContactsFeature = true;
        if (Array.isArray(config.CONTACTS.SAVE_CONTACT_TO_DEVICE.RESTRICTIONS)) {
          const restriction = config.CONTACTS.SAVE_CONTACT_TO_DEVICE.RESTRICTIONS.find(
            (restr) => restr.pageKey === USER_DATA_PAGE_KEY
          );
          if (restriction) {
            // A restriction has been defined for this page
            if (restriction.for.indexOf(this.props.profile) !== -1) {
              // Restriction applies for this profile, so skip rendering share button
              hasContactsFeature = false;
            }
          }
        }
      }
      if (hasContactsFeature) {
        tabsData.push(this.metContactsTabData);
        this.tabIndexMapping.push(MET_CONTACTS_TAB_KEY);
      }
    }
    if (hasContactedParticipants) {
      tabsData.push(this.contactedParticipantsTabData);
      this.tabIndexMapping.push(CONTACTED_PARTICIPANTS_TAB_KEY);
    }

    let multipleTabs =
      hasEbadge ||
      hasEvents ||
      hasContacts ||
      hasContactedParticipants ||
      hasProjectVisitor ||
      hasMeetingContacts;

    return (
      <div id="ud-content-container" className="content-below-apptoolbar">
        {multipleTabs && (
          <Tabs
            currentIndex={
              this.props.tabIndex !== undefined
                ? this.props.tabIndex
                : this.tabIndexMapping.indexOf(this.props.tabKey)
            }
            onTabIndexChange={this.onTabClick}
            data={tabsData}
          />
        )}
        {this.renderTabContent()}
      </div>
    );
  }
}

UserDataContent.propTypes = {
  userData: PropTypes.object,
  tabIndex: PropTypes.number,

  userProjectsItems: PropTypes.object,

  contacts: PropTypes.array,
  contactedParticipants: PropTypes.array,
  searchResults: PropTypes.object,

  formStatus: PropTypes.object,
  formSections: PropTypes.array.isRequired,

  tosLink: PropTypes.string,
  logout: PropTypes.func.isRequired,
  keyboardHeight: PropTypes.number,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  profile: PropTypes.string.isRequired,
};

export default UserDataContent;
