import moment from 'moment';

import config from 'app-customs/config/config';

import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { getMobileId } from 'src/core/cordova/CordovaHelper';
import { getBindedActions } from 'src/store/bindedActions';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { get as getLabels } from 'src/core/Lang';

import {
  FIELD_TYPES,
  getContext,
  getFormFields as getKnownFormFields,
  splitFields,
  getContacts,
  saveContacts,
} from 'src/core/klipso-leads/KlipsoLeadsData';
import { WS_ERRORS } from '../WS_ERRORS';

const LOG_PREF = '[KlipsoLeadsWS] ';

// DOC: https://mobile-spot.atlassian.net/wiki/spaces/FR/pages/107806721/In-App+Klipso+Leads

export const TEST_DATA = {
  // licence: '724VYAAAMP',
  // userName: 'dev',
  mobileId: 'c35dc8c7d38ae1e1',
  mobileName: 'test smartphone',
  // InstanceName: 'Leni',
  // ExhibitorId: "ef325617-82a7-33cf-cc42-238785d96901",
  // ExhibitorName: "MOBILE SPOT",
  // EventDateBegin: "15/04/2017 00:00:00",
  // EventDateEnd: "31/12/2020 00:00:00",
  // EventId: "761502c1-23e4-e411-b9c7-005056a13c8a",
  // EventName: "Master PE Gratuit",
  // CheckPointId: "d3ae1fd7-063c-e911-80f9-005056ae1179",
  FormFields: [
    {
      CABPosition: 1,
      Code: 'Prenom',
      Codifications: [],
      DefaultValue: '',
      Id: 'Prenom',
      IsMandatory: false,
      IsReadOnly: false,
      Label: 'Prénom',
      Nature: 0,
      PrintPosition: 1,
      Type: 0,
    },
    {
      CABPosition: 2,
      Code: 'Nom',
      Codifications: [],
      DefaultValue: '',
      Id: 'Nom',
      IsMandatory: false,
      IsReadOnly: false,
      Label: 'Nom',
      Nature: 0,
      PrintPosition: 2,
      Type: 0,
    },
    {
      CABPosition: 3,
      Code: 'Societe',
      Codifications: [],
      DefaultValue: '',
      Id: 'Societe',
      IsMandatory: false,
      IsReadOnly: false,
      Label: 'Société',
      Nature: 0,
      PrintPosition: 3,
      Type: 0,
    },
    {
      CABPosition: 4,
      Code: 'Email',
      Codifications: [],
      DefaultValue: '',
      Id: 'Email',
      IsMandatory: false,
      IsReadOnly: false,
      Label: 'Email',
      Nature: 0,
      PrintPosition: 4,
      Type: 0,
    },
    {
      CABPosition: null,
      Code: 'AutreSociete',
      Codifications: [],
      DefaultValue: 'Autre société par défault',
      Id: 'AutreSociete',
      IsMandatory: true,
      IsReadOnly: false,
      Label: 'Autre société',
      Nature: 3,
      PrintPosition: null,
      Type: 0,
    },
    {
      CABPosition: null,
      Code: 'Date',
      Codifications: [],
      DefaultValue: '',
      Id: 'AutreSociete',
      IsMandatory: false,
      IsReadOnly: false,
      Label: 'Date',
      Nature: 3,
      PrintPosition: null,
      Type: 2,
    },
    {
      CABPosition: null,
      Code: 'AutreEmail',
      Codifications: [],
      DefaultValue: '',
      Id: 'AutreEmail',
      IsMandatory: false,
      IsReadOnly: false,
      Label: 'AutreEmail',
      Nature: 3,
      PrintPosition: null,
      Type: 8,
    },
    {
      CABPosition: null,
      Code: 'Boolean',
      Codifications: [],
      DefaultValue: true,
      Id: 'Promotion',
      IsMandatory: false,
      IsReadOnly: false,
      Label: 'Accepter de recevoir des promotions',
      Nature: 3,
      PrintPosition: null,
      Type: 4,
    },
    {
      CABPosition: null,
      Code: 'Nombre',
      Codifications: [],
      DefaultValue: '',
      Id: 'Commentaire',
      IsMandatory: false,
      IsReadOnly: false,
      Label: 'Nombre',
      Nature: 3,
      PrintPosition: null,
      Type: 3,
    },
    {
      CABPosition: null,
      Code: 'Choix unique',
      Codifications: [
        { Code: 'C001', Id: 'd4e04299-6dab-5882-307e-70d829477cf7', Label: 'A' },
        { Code: 'C002', Id: '26b2d792-22fd-65d9-a64e-f7e614653690', Label: 'B' },
        { Code: 'C003', Id: '5b5912a7-4b96-2dcd-1cca-bc2db3453b85', Label: 'C' },
        { Code: 'C004', Id: '986c7628-1288-b698-7b5a-479fb2ca02ba', Label: 'D' },
      ],
      DefaultValue: '',
      Id: '53B2D481-4A06-9940-9417-80D501BF9356',
      IsMandatory: true,
      IsReadOnly: false,
      Label: 'Question choix unique',
      Nature: 2,
      PrintPosition: null,
      Type: 5,
    },
    {
      CABPosition: null,
      Code: 'Test long choix multiple',
      Codifications: [
        { Code: 'C001', Id: '37', Label: 'valeur 1' },
        { Code: 'C002', Id: 'a9', Label: 'valeur 2' },
        { Code: 'C003', Id: 'e9', Label: 'plutôt longue valeur 3, blabla' },
        {
          Code: 'C004',
          Id: 'dd',
          Label: 'très très longue valeur 4, label très très long, blabla bla bla',
        },
        { Code: 'C005', Id: 'PO9', Label: 'fausse codif 5' },
        { Code: 'C006', Id: 'PI9', Label: 'fausse codif 6' },
        { Code: 'C007', Id: 'PM9', Label: 'fausse codif 7' },
        { Code: 'C008', Id: 'PU9', Label: 'fausse codif 8' },
        { Code: 'C009', Id: 'PY9', Label: 'fausse codif 9' },
      ],
      DefaultValue: '',
      Id: 'PRF',
      IsMandatory: false,
      IsReadOnly: false,
      Label: 'Test longue question choix multiple - longs labels - blabbla',
      Nature: 2,
      PrintPosition: null,
      Type: 6,
    },
  ],
};

const TEST_DATA_BADGES = [
  'WXJFEDHGN&Alexandre#CHRUN#LENI#achrun@leni.fr#', // badge 1
  'WPQLP8FND&GAEL#FERREROCOLUN#EXHIBIS#ojoly@leni.fr#', // badge 2
  'WKG6W4BGS&Test#TEST PAYANT#LENI#eperez@leni.fr#', // badge 3
  'W42QVVJQY&Machat#HAIFA#LENI#hmachat@leni.fr#', // badge 4
  'WEVXATH3X&PRENOM#NOM#EXHIBIS#ojoly@leni.fr#', // badge 5
  'HW744RQ86H&Ribeiro#CHRISTINE#VACATAIRE#ducat.c@live.fr#', // salon infirmier

  // Generated dummy data:
  'QUI4OOF3D&Lyons#Potts#KIOSK#XOGGLE#celestedodson@xoggle.com#',
  'GEIXI4IAN&Duran#Thompson#ROTODYNE#duranthompson@rotodyne.com#',
  'EIGH5AHG7&Lesa#Yang#MAGMINA#lesayang@magmina.com#',
  'HAI9WEEZO&Holly#Pate#DAYCORE#hollypate@daycore.com#',
  'OHPHIES3J&Diann#Raymond#DIGIQUE#diannraymond@digique.com#',
  'AHR6EEY3E&Townsend#Parker#EGYPTO#townsendparker@egypto.com#',
  'AHJ7MEECH&Cannon#Mosley#ZYTRAX#cannonmosley@zytrax.com#',
  'TU7PHIENI&Carmen#David#COMVENE#carmendavid@comvene.com#',
  'EYA3EAPHE&Lindsay#Wise#URBANSHEE#lindsaywise@urbanshee.com#',
  'BIE7KA3UI&Susana#Bishop#MAXIMIND#susanabishop@maximind.com#',
  'NEJEI7AIY&Heidi#Brown#GYNK#heidibrown@gynk.com#',
  'AIGH3VIPH&Powers#Roman#ZAJ#powersroman@zaj.com#',
  'UZEICAH8I&Blanca#Mcintosh#INSURETY#blancamcintosh@insurety.com#',
  'CIW6IMEI5&Abigail#Campos#QIMONK#abigailcampos@qimonk.com#',
  'TOOF7OOWU&Clark#Alvarez#RODEOLOGY#clarkalvarez@rodeology.com#',
  'IECH2EIVA&Kerr#Park#IZZBY#kerrpark@izzby.com#',
  'SIE9MU1SH&Butler#Whitfield#EARBANG#butlerwhitfield@earbang.com#',
  'IED9GOHZ4&Bullock#Velez#PORTICA#bullockvelez@portica.com#',
  'VO5VAHVU3&Gay#Kline#OTHERWAY#gaykline@otherway.com#',
  'AEPEEYAH7&Le#Howell#HIVEDOM#lehowell@hivedom.com#',
  'UQU9OHHEE&Boone#Brennan#GEEKULAR#boonebrennan@geekular.com#',
  'OVE0ACAHR&Helen#Curry#CINCYR#helencurry@cincyr.com#',
  'EU4AHVAIB&Caitlin#Nichols#ZANITY#caitlinnichols@zanity.com#',
  'IEK9IEMO8&Golden#Chambers#DELPHIDE#goldenchambers@delphide.com#',
  'XEIM8AZ0H&Maura#Simon#KYAGURU#maurasimon@kyaguru.com#',
  'WAI8EEYIE&Stewart#Adkins#TALKOLA#stewartadkins@talkola.com#',
  'WOH8PHIEY&Garrison#Turner#GEEKOSIS#garrisonturner@geekosis.com#',
  'EEV9FAESH&Donaldson#Farley#GEEKKO#donaldsonfarley@geekko.com#',
  'DIESH5XEE&Gutierrez#Nash#NEWCUBE#gutierreznash@newcube.com#',
  'FO7EENGAE&Allie#Moses#ORBIFLEX#alliemoses@orbiflex.com#',
  'EIXOHB9OU&Kirsten#Gilliam#EXOTERIC#kirstengilliam@exoteric.com#',
  'ZEN1OHB8M&Stokes#Beard#SIGNIDYNE#stokesbeard@signidyne.com#',
  'FEI5CEI6U&Larson#Ochoa#SKYPLEX#larsonochoa@skyplex.com#',
  'EEFAHYO8Z&Kane#Whitley#ACCRUEX#kanewhitley@accruex.com#',
  'EETHEMA7M&Harriett#Arnold#MARVANE#harriettarnold@marvane.com#',
  'AJAZAE1XO&Luna#Kirkland#COMSTAR#lunakirkland@comstar.com#',
  'NAEG3OHH1&Kristine#Benjamin#SNIPS#kristinebenjamin@snips.com#',
  'EERAT8HAH&Kirby#Decker#FLUM#kirbydecker@flum.com#',
  'THEW4AELU&Villarreal#Sears#SPACEWAX#villarrealsears@spacewax.com#',
  'AID6EISH5&Patti#Osborn#ZENSUS#pattiosborn@zensus.com#',
  'JIIGA8ZEI&Teresa#Blackwell#RUBADUB#teresablackwell@rubadub.com#',
  'AEPEI3IEZ&Joann#Crane#PLASTO#joanncrane@plasto.com#',
  'PHAJAEH2K&Spencer#Terrell#GOLOGY#spencerterrell@gology.com#',
  'AHSHAH6PH&May#Avila#PLASMOS#mayavila@plasmos.com#',
  'YAEW8YEAJ&Carolina#Watson#MOLTONIC#carolinawatson@moltonic.com#',
  'GAA0DE2JO&Francine#Sloan#SILODYNE#francinesloan@silodyne.com#',
  'VAINGOHY7&Debbie#Glenn#OVERPLEX#debbieglenn@overplex.com#',
  'AEM5AIHIT&Norris#Sandoval#TELEQUIET#norrissandoval@telequiet.com#',
  'EIPOHT0HU&Stevenson#Floyd#RAMJOB#stevensonfloyd@ramjob.com#',
  'EUM3OOGH7&Richmond#Benson#QUALITERN#richmondbenson@qualitern.com#',
  'ED6ROHTH4&Barker#Zimmerman#XYMONK#barkerzimmerman@xymonk.com#',
  'LAE7MEIKE&Williams#Schneider#STROZEN#williamsschneider@strozen.com#',
  'XO0OOSH2E&Whitaker#Valenzuela#HOPELI#whitakervalenzuela@hopeli.com#',
  'EQUOHTH6P&Tonya#Mercer#DEVILTOE#tonyamercer@deviltoe.com#',
  'UUK7TOH5A&Jones#Wade#ELITA#joneswade@elita.com#',
  'UQU9WA8EE&Pam#Hyde#NETPLAX#pamhyde@netplax.com#',
  'UCEE3NAIL&Cole#Parks#AQUASSEUR#coleparks@aquasseur.com#',
  'PHEELAI2I&Sabrina#Oneil#CYCLONICA#sabrinaoneil@cyclonica.com#',
  'OY2HAAVAH&Bennett#Benton#COSMOSIS#bennettbenton@cosmosis.com#',
  'OU8VEE7TU&Rollins#Paul#COMTOUR#rollinspaul@comtour.com#',
  'OEYEIX6AA&Stacey#Davenport#ANIXANG#staceydavenport@anixang.com#',
  'AIWAI2SHE&Murphy#Larsen#DIGINETIC#murphylarsen@diginetic.com#',
  'XUHEH8FIE&Michele#Gilmore#SHOPABOUT#michelegilmore@shopabout.com#',
  'BAI3RAH8O&June#Navarro#SPRINGBEE#junenavarro@springbee.com#',
  'CHEW6SHO3&Genevieve#Barber#ZIPAK#genevievebarber@zipak.com#',
  'REEP6FEI0&Hampton#Hewitt#VENDBLEND#hamptonhewitt@vendblend.com#',
  'IEW2YEIVA&Jeannette#Hensley#QIMONK#jeannettehensley@qimonk.com#',
  'POHL8WAHK&Ballard#Bridges#PHOLIO#ballardbridges@pholio.com#',
  'DIU1AHGH8&Ida#Leon#ARCTIQ#idaleon@arctiq.com#',
  'OOGHEIX3P&Hayden#Wooten#ULTRASURE#haydenwooten@ultrasure.com#',
  'EIZ3UCH4A&Lesa#Joyce#PAPRIKUT#lesajoyce@paprikut.com#',
  'ENEAFUG1R&Shelly#Wright#ISODRIVE#shellywright@isodrive.com#',
  'OHQU0EEPH&Deann#Hoffman#LETPRO#deannhoffman@letpro.com#',
  'OU1PUGH5T&Janell#Doyle#LIQUICOM#janelldoyle@liquicom.com#',
  'APE4EIGHE&Diaz#Scott#MINGA#diazscott@minga.com#',
  'ITU2SHAI9&Rios#Gallagher#PULZE#riosgallagher@pulze.com#',
  'JIE5AUXAE&Lindsay#Mejia#CORPORANA#lindsaymejia@corporana.com#',
  'AUGH6AHZU&Karyn#Silva#VIRVA#karynsilva@virva.com#',
  'BEEBAEJ0S&Lamb#Humphrey#ZOLARITY#lambhumphrey@zolarity.com#',
  'CHIET3SHU&Sutton#Hendricks#ORBAXTER#suttonhendricks@orbaxter.com#',
  'IZAHC4SOH&Stein#Underwood#CEDWARD#steinunderwood@cedward.com#',
  'OETH4EECH&Osborn#Clemons#KATAKANA#osbornclemons@katakana.com#',
  'VEFOOVO9T&Kara#Baird#ULTRASURE#karabaird@ultrasure.com#',
  'KE1OHSHOH&Adrian#Parrish#STEELTAB#adrianparrish@steeltab.com#',
  'TOOHU1QUE&Lenore#Contreras#INSOURCE#lenorecontreras@insource.com#',
  'IENG7IEKI&Kinney#Rodriquez#RUBADUB#kinneyrodriquez@rubadub.com#',
  'SHU5AHD2S&Gwendolyn#Carrillo#ORBIFLEX#gwendolyncarrillo@orbiflex.com#',
  'EITH5OHGH&Lawrence#Frank#FUTURIS#lawrencefrank@futuris.com#',
  'IICHEE6BO&Boyd#Harding#CORMORAN#boydharding@cormoran.com#',
  'ZEIT3CHIR&Reid#Walsh#COMTRAK#reidwalsh@comtrak.com#',
  'GA6QUAPH4&Morton#Randolph#FORTEAN#mortonrandolph@fortean.com#',
  'CAE6TEI7H&Harrington#Rosales#INSURESYS#harringtonrosales@insuresys.com#',
  'OP1IETIUF&Freda#Sanchez#MAGMINA#fredasanchez@magmina.com#',
  'VOIZ8ZIE1&Alba#Christensen#VALPREAL#albachristensen@valpreal.com#',
  'ENGEEPHE0&Jordan#Wells#GEEKKO#jordanwells@geekko.com#',
  'URAH4OHPE&Raymond#Barrera#UTARA#raymondbarrera@utara.com#',
  'ASAB0JEI3&Florence#Dorsey#NAMEGEN#florencedorsey@namegen.com#',
  'OOHAEX2JO&Hernandez#Johns#ZINCA#hernandezjohns@zinca.com#',
  'OOP2EEXEI&Cristina#Phillips#SUNCLIPSE#cristinaphillips@sunclipse.com#',
  'IQUIEB4AM&Lane#Wood#OTHERWAY#lanewood@otherway.com#',
  'AEWEI0AHS&Coleen#Burnett#GEOFARM#coleenburnett@geofarm.com#',
  'SAI5HEI9G&Ellen#Poole#NETPLAX#ellenpoole@netplax.com#',
  'EOM4APH2U&Lesley#Hester#QNEKT#lesleyhester@qnekt.com#',
  'XOOR2CAIV&Tina#Abbott#TETAK#tinaabbott@tetak.com#',
  'WOOBE1MAE&Cohen#Skinner#ROCKYARD#cohenskinner@rockyard.com#',
  'AECHULE8E&Roman#Case#EXTREMO#romancase@extremo.com#',
  'GAIVEI4RA&Maxine#Kirk#EXOSPEED#maxinekirk@exospeed.com#',
  'POO6AEC6U&Deann#Mcguire#ZENSURE#deannmcguire@zensure.com#',
  'IV1KAGHAI&Deidre#Bartlett#ORBIXTAR#deidrebartlett@orbixtar.com#',
  'IOWAO3WAI&Shelly#Sharp#ZIDOX#shellysharp@zidox.com#',
  'BAIT2AIR4&Allie#Howell#EZENT#alliehowell@ezent.com#',
  'EISHOO7SU&Alston#Santana#TOURMANIA#alstonsantana@tourmania.com#',
  'UG2OOTHAI&Henrietta#Mcleod#FIBRODYNE#henriettamcleod@fibrodyne.com#',
  'OHDAE1YIG&Jeanette#Becker#ASSITIA#jeanettebecker@assitia.com#',
  'OODUF8AEC&Margo#Wallace#MITROC#margowallace@mitroc.com#',
  'IEGOOD3AI&Weiss#Roman#BESTO#weissroman@besto.com#',
  'IR2OOLEAV&Lois#Sawyer#SLOFAST#loissawyer@slofast.com#',
  'SAHTH2HAE&Bernadette#Jones#EXOSWITCH#bernadettejones@exoswitch.com#',
  'IEY4OHNUU&Lolita#Mueller#SKYBOLD#lolitamueller@skybold.com#',
  'DO9EYIYEE&Mattie#Puckett#PATHWAYS#mattiepuckett@pathways.com#',
  'AIK1EU8OO&Tracey#Coffey#XYLAR#traceycoffey@xylar.com#',
  'OOPAEQUO9&Buchanan#Leon#NIXELT#buchananleon@nixelt.com#',
  'IECH1ZUP8&Roberson#Smith#PETICULAR#robersonsmith@peticular.com#',
  'RIEHI1OHG&Gilda#Chang#DOGNOST#gildachang@dognost.com#',
  'EEVAI6SHU&Antoinette#Walker#CONCILITY#antoinettewalker@concility.com#',
  'THEIC5PHA&Jean#Velazquez#ACCUPRINT#jeanvelazquez@accuprint.com#',
  'RE1VOHM3S&Amparo#Sweet#SYBIXTEX#amparosweet@sybixtex.com#',
  'ZU8ZEEGHI&Donovan#Dillard#PYRAMI#donovandillard@pyrami.com#',
  'IEM4OW8RU&Concepcion#Stewart#BRISTO#concepcionstewart@bristo.com#',
  'OHCIEMO9D&Rachelle#Morales#COMVEYER#rachellemorales@comveyer.com#',
];

export function getTestDataBadge() {
  const randomIndex = Math.floor(Math.random() * TEST_DATA_BADGES.length);
  return TEST_DATA_BADGES[randomIndex];
}

/**
 * Check licence
 *
 * response sample:
 * {
      "AuthenticateExhibitorAppByCABResult": {
        "AuthenticationStatus": 0,
        "AuthenticationStatusDescription": "OK",
        "EventDateBegin": "15/04/2017 00:00:00",
        "EventDateEnd": "31/12/2020 00:00:00",
        "EventId": "761502c1-23e4-e411-b9c7-005056a13c8a",
        "EventName": "Master PE Gratuit",
        "ExhibitorId": "ef325617-82a7-33cf-cc42-238785d96901",
        "ExhibitorName": "MOBILE SPOT",
        "InstanceName": "Leni",
        "MobileServicesUrl": "http://www.e-registration.net/WebServices/WS_Gaia_Mobile/GaiaMobile/",
        "Users": [
            "iPhone de Benoît",
            "iPhone de Rayk"
        ],
        "Login": "zaK4eP",
        "Password": "xwuq7q"
      }
   }
 */
export const checkLicence = (CAB, mobileId) =>
  new Promise(function(resolve, reject) {
    const opts = {
      method: 'POST',
      headers: [HEADERS.JSON_CONTENT_TYPE],
      body: JSON.stringify({
        CAB,
        mobileId,
      }),
    };
    fetchHelper(
      config.KLIPSO_LEADS.CHECK_LICENCE_WS_URL,
      opts,
      true, // parse response as json
      function(response) {
        if (!response || !response.AuthenticateExhibitorAppByCABResult) {
          console.error(`${LOG_PREF}Failed to check licence because of empty response`, response);
          reject(WS_ERRORS.UNKNOWN);
        } else if (
          response.AuthenticateExhibitorAppByCABResult.AuthenticationStatusDescription !== 'OK'
        ) {
          reject(WS_ERRORS.INVALID_LICENCE);
        } else {
          resolve(response.AuthenticateExhibitorAppByCABResult);
        }
      },
      function onFailure(error, message) {
        console.error(`${LOG_PREF}Failed to check licence / CAB:${CAB} / mobileId:${mobileId}`);
        reject(WS_ERRORS.NETWORK);
      },
      false // showModalOnError
    );
  });

/**
 * Register a device (checkpoint)
 *
 * response sample:
 * {
     "RegisterCheckPointResult": {
        "CheckPointId": "d3ae1fd7-063c-e911-80f9-005056ae1179",
        "FormFields": [
            {
                "CABPosition": 1,
                "Code": "Prenom",
                "Codifications": [],
                "DefaultValue": "",
                "Id": "Prenom",
                "IsMandatory": false,
                "IsReadOnly": false,
                "Label": "Prénom",
                "Nature": 0,
                "PrintPosition": 1,
                "Type": 0
            },
            ...
        ],
        "IsAutorizedCreatVisitor": "False",
        "IsAutorizedVoiceMessage": "False",
        "RegisterStatus": 0,
        "RegisterStatusDescription": "OK"
      }
    }
 */
export const registerCheckpoint = (instance, exhId, userName, mobileId, mobileName) =>
  new Promise(function(resolve, reject) {
    const opts = {
      method: 'POST',
      headers: [HEADERS.JSON_CONTENT_TYPE],
      body: JSON.stringify({
        instanceName: instance,
        exhibitorId: exhId,
        userName: userName + ' ' + mobileName.substring(0, 3) + mobileId.substring(0, 3),
        mobileId,
      }),
    };
    fetchHelper(
      config.KLIPSO_LEADS.REGISTER_CHECKPOINT_WS_URL,
      opts,
      true, // parse response as json
      function(response) {
        if (!response || !response.RegisterCheckPointResult) {
          console.error(
            `${LOG_PREF}Failed to register checkpoint because of empty response`,
            response
          );
          reject(WS_ERRORS.UNKNOWN);
        } else if (response.RegisterCheckPointResult.RegisterStatus === 1) {
          // Le nombre de mobiles référencés est supérieur au droit d'utilisation
          reject(WS_ERRORS.MAX_LICENCE);
        } else if (response.RegisterCheckPointResult.RegisterStatus === 2) {
          // Aucun droit d'utilisation
          reject(WS_ERRORS.NO_AUTHORIZATION);
        } else if (response.RegisterCheckPointResult.RegisterStatus !== 0) {
          // Autre erreur
          console.error(
            `${LOG_PREF}Failed to register checkpoint because of unexpected response`,
            response
          );
          reject(WS_ERRORS.UNKNOWN);
        } else {
          resolve(response.RegisterCheckPointResult);
        }
      },
      function onFailure(error, message) {
        console.error(
          `${LOG_PREF}Failed to register checkpoint / exhId:${exhId} / userName:${userName} / mobileId:${mobileId}`
        );
        reject(WS_ERRORS.NETWORK);
      },
      false // showModalOnError
    );
  });

/**
 * Get form fields
 *
 * reponse sample:
 * {
    "GetFormFieldsResult": [
        {
            "CABPosition": 1,
            "Code": "Prenom",
            "Codifications": [],
            "DefaultValue": "",
            "Id": "Prenom",
            "IsMandatory": false,
            "IsReadOnly": false,
            "Label": "Prénom",
            "Nature": 0,
            "PrintPosition": 1,
            "Type": 0
        },
        ...
    ],
    "GetFormFieldsStatus": 0,
    "GetFormFieldsStatusDescription": "OK"
  }
 */
export const getFormFields = (exhId, mobileId) =>
  new Promise(function(resolve, reject) {
    const opts = {
      method: 'POST',
      headers: [HEADERS.JSON_CONTENT_TYPE],
      body: JSON.stringify({
        instanceName: config.KLIPSO_LEADS.INSTANCE_NAME,
        exhibitorId: exhId,
        mobileId,
      }),
    };
    fetchHelper(
      config.KLIPSO_LEADS.GET_FORM_FIELDS_WS_URL,
      opts,
      true, // parse response as json
      resolve,
      function onFailure(error, message) {
        console.error(
          `${LOG_PREF}Failed to get form field / exhId:${exhId} / mobileId:${mobileId}`
        );
        reject(WS_ERRORS.NETWORK);
      },
      false // showModalOnError
    );
  });

/*
{
    VisitorToSave: {
        CAB: "WLYAT68QF",
        VisitHistoricList: [{
            CheckPointId: "e44e406c-5f3e-e911-8100-005056ae26bf",
            ContactName: "TCL 5051D",
            DateVisit: "04\/03\/2019 10:30:29"
        }],
        ExternalId: "098ebcea-3dc5-47c1-be25-9b2e50e13f78",
        IsExistPhoto: false,
        IsExistVoiceMessage: false,
        FieldValueList: [
            {
                Id: "AutreNom",
                Label: "Autre Nom",
                Value: "autre nom",
                Values: [],
                Codes: []
            },
            ...
        ]
    },
    SaveAction: 1
},
*/

const KLEADS_BACKEND_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export function parseStringifiedDateFromKLeadsBackend(string) {
  return moment(string, KLEADS_BACKEND_DATE_FORMAT)
    .toDate()
    .getTime();
}

function formatDateForKLeadsBackend(date) {
  return moment.utc(date).format(KLEADS_BACKEND_DATE_FORMAT);
}

/**
 * @param  {array} contacts
 * @param  {object} context
 * @return {array}
 */
function transformContactsToSendToWebService(contacts, context) {
  const { editableFields } = splitFields(getKnownFormFields());

  const arrayToSend = contacts.map((contact) => ({
    VisitorToSave: {
      CAB: contact.code,
      VisitHistoricList: contact.history.map((h) => ({
        CheckPointId: context.checkPointId,
        ContactName: context.userName,
        DateVisit: formatDateForKLeadsBackend(h.date),
      })),
      ExternalId: '098ebcea-3dc5-47c1-be25-9b2e53e13g78', // contact.guid,
      IsExistPhoto: false,
      IsExistVoiceMessage: false,
      FieldValueList: editableFields.map(function(formField) {
        const isSingleOrMultiChoice =
          formField.Type === FIELD_TYPES.SINGLE_CHOICE ||
          formField.Type === FIELD_TYPES.MULTI_CHOICE;
        const value = contact[formField.Code] || null;

        return {
          Id: formField.Id,
          Code: formField.Code,
          Label: formField.Label,
          Value: isSingleOrMultiChoice ? '' : value,
          Values: isSingleOrMultiChoice
            ? Array.isArray(value)
              ? value
              : value === null
              ? []
              : [value]
            : [],
        };
      }),
    },
    SaveAction: 1,
  }));
  return arrayToSend;
}

/**
 * Save visitors list
 *
 * response sample:
 * {
     "SaveVisitorRelationListResult": [
        {
            "CAB": "WLYAT68QF",
            "ExternalId": "098ebcea-3dc5-47c1-be25-9b2e50e13f78",
            "InternalId": "e5f30dea-81d5-d42a-ea2d-14a20d5865a9",
            "IsSaveOk": false
        },
        {
            "CAB": "WPQLP8FND",
            "ExternalId": "6d60face-dd89-4516-bbad-3d09c167bbf1",
            "InternalId": "6f6786c2-a25b-23f1-52f6-0fed0ac8d613",
            "IsSaveOk": false
        }
    ]
  }
 */
export const saveVisitorRelationList = () =>
  new Promise(function(resolve, reject) {
    // Filter contacts to send
    const contacts = getContacts();
    const contactsToSync = contacts.filter((contact) => !contact.isSync);

    if (contactsToSync.length < 1) {
      console.info(`${LOG_PREF + contactsToSync.length} contact to sync - skipping WS call`);
      resolve();
      return;
    }

    console.info(`${LOG_PREF}About to sync ${contactsToSync.length} contacts`);

    const context = getContext();
    let mobileId = getMobileId();

    // DEV - mock mobileId
    if (config.ENV === 'dev' && !global.isCordovaContext) {
      mobileId = TEST_DATA.mobileId;
    }

    // Transform contacts data
    const arrayToSend = transformContactsToSendToWebService(contactsToSync, context);

    // Call WS
    const opts = {
      method: 'POST',
      headers: [HEADERS.JSON_CONTENT_TYPE],
      body: JSON.stringify({
        instanceName: context.instance,
        exhibitorId: context.exhibitor.id,
        mobileId,
        visitorToSaveList: arrayToSend,
      }),
    };
    fetchHelper(
      config.KLIPSO_LEADS.SAVE_WS_URL,
      opts,
      true, // parse response as json
      function onSuccess(response) {
        const listResults = response.SaveVisitorRelationListResult;
        if (!listResults || Array.isArray(listResults) !== true) {
          reject(WS_ERRORS.UNKNOWN);
          return;
        }

        // Avoids looping on contacts for each result
        const contactsCabIndexPairs = {};
        contacts.forEach(function(contact, index) {
          contactsCabIndexPairs[contact.code] = index;
        });

        const notSavedContacts = [];

        // Iterate on results to update contact 'lastSync' property
        listResults.forEach(function(result) {
          if (result.IsSaveOk !== true) {
            console.warn(`${LOG_PREF}Failed to save contact`, result);
            notSavedContacts.push(result);
            return;
          }
          const contactIndex = contactsCabIndexPairs[result.CAB];
          if (typeof contactIndex !== 'number') {
            // safety net - pretty unexpected case
            console.error(
              `${LOG_PREF}Contact matching CAB ${result.CAB} not found among local data`,
              result
            );
            return;
          }
          const contact = contacts[contactIndex];

          // Update contact
          contact.lastSync = new Date().getTime();
          contact.isSync = true;
          contacts.splice(contactIndex, 1, contact);
        });

        // Persist contacts and dispatch to store
        saveContacts(contacts);

        // Show notification about not saved contacts - disabled -
        // showNotSavedContactsNotification(notSavedContacts, contactsToSync);

        resolve();
      },
      function onFailure(error, message) {
        console.error(`${LOG_PREF}Failed to save visitors`, contactsToSync, error, message);
        reject(WS_ERRORS.NETWORK);
      },
      false // showModalOnError
    );
  });

function showNotSavedContactsNotification(notSavedContacts, contactsToSync) {
  if (notSavedContacts.length > 0) {
    const notSavedContactNames = [];
    notSavedContacts.forEach(function(saveResult) {
      if (saveResult.CAB) {
        const contact = contactsToSync.find((contact) => contact.code === saveResult.CAB);
        if (contact) {
          notSavedContactNames.push(`<li>${contact.Prenom} ${contact.Nom}</li>`);
        }
      }
    });

    if (notSavedContactNames.length < notSavedContacts.length) {
      // Not all contacts name have been found - seems unexpected
      notSavedContactNames.push('<li>...</li>');
    }

    getBindedActions().showNotification({
      message: `${getLabels().klipsoLeads.saveContactsError(
        notSavedContacts.length
      )}<ul>${notSavedContactNames.join('')}</ul>`,
      level: NotificationLevels.ERROR,
    });
  }
}
