import { GOOGLE_ANALYTICS_ID, GOOGLE_ANALYTICS_SEND_INTERVAL } from 'app-customs/config/config';
import isOnline from 'src/core/util/isOnline';
import { getBuildVersion, isCordovaContext } from 'src/core/util/browser';
import { get as getProfile } from 'src/core/Profile';

const LOG_PREF = '[GoogleAnalyticsHelper] ';

// let isReady = false;

/**
 * Indicate if Google Analytics is used
 * @return {boolean}
 */
export const isEnabled = () => {
  return typeof GOOGLE_ANALYTICS_ID === 'string' && GOOGLE_ANALYTICS_ID.length > 0;
};

/**
 * Persist client id through sessions
 * @param  {string}
 * @return {string}
 */
const persistence = (() => {
  const STORAGE_CID_KEY = 'ua-cid';

  function setClientId(value) {
    localStorage.setItem(STORAGE_CID_KEY, value);
  }

  const getClientId = () => localStorage.getItem(STORAGE_CID_KEY);

  return {
    setClientId,
    getClientId,
  };
})();

/**
 * Manage the queue of pageview, events, etc to send
 */
const queue = (() => {
  const _queue = [];
  let processingInterval;
  const appType = isCordovaContext() ? 'MobileApp' : 'WebApp';
  /**
   * Send everything queued
   */
  function _empty() {
    if (processingInterval) {
      // Already processing
      return;
    }

    console.debug(`${LOG_PREF}Processing queue`);

    processingInterval = window.setInterval(() => {
      while (_queue.length > 0) {
        window.gtag('set', { dimension1: appType });
        window.gtag('set', { dimension2: getProfile() });
        window.gtag('event', _queue?.action, _queue.pop());
      }
      window.clearInterval(processingInterval);
      processingInterval = null;
    }, GOOGLE_ANALYTICS_SEND_INTERVAL);
  }

  /**
   * Process queue only if device is online
   */
  function empty() {
    if (processingInterval) {
      // Already processing
      return;
    }
    isOnline((result) => {
      if (result) {
        _empty();
      }
    });
  }

  /**
   * @param {object} opts
   */
  function add(opts) {
    if (typeof opts === 'object') {
      console.debug(`${LOG_PREF}Add to queue`);
      _queue.push(opts);
      // Process queue
      empty();
    } else {
      console.error(
        `${LOG_PREF}Cannot add argument to queue as object is expected but got: ${typeof opts}`
      );
    }
  }

  return {
    add,
    empty,
  };
})();

var loadScript = function (src, async) {
  const script = document.createElement("script");
  script.async = async;
  script.src = src;
  document.body.appendChild(script);

};

/**
 * Load GA library and initialize GA tracker
 */
export function init() {
  if (isEnabled()) {
    // Fallback
    if (!window.gtag) {
      console.info(`${LOG_PREF}Fallback, loading library via www`);
      loadScript(`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`, true);
      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() {
        window.dataLayer.push(arguments);
      };
      gtag('js', new Date());
    }

    // Init
    const options = {
      trackingId: GOOGLE_ANALYTICS_ID,
      app_name: process.env.APP_NAME,
      cookie_domain: 'none',
      storage: 'none',
      send_page_view: false
    };
    if (isCordovaContext() && window.device) {
      options.clientId = persistence.getClientId() || window.device.uuid;
    } else {
      options.clientId = persistence.getClientId();
    }
    window.gtag('config', GOOGLE_ANALYTICS_ID, options);

    window.gtag('set', { app_name: process.env.APP_NAME });
    window.gtag('set', { app_version: process.env.APP_VERSION });
    window.gtag('set', { appName: process.env.APP_NAME });
    window.gtag('set', { appVersion: process.env.APP_VERSION });
    window.gtag('set', { buildVersion: getBuildVersion() });

    // Allow if current page protocol is different from http(s):// (needed for cordova)
    window.gtag('set', { checkProtocolTask: null });

    // Listen to ready callback
    window.gtag('get', GOOGLE_ANALYTICS_ID, 'client_id', (defaultTracker) => {
      // isReady = true;
      console.info(
        `${LOG_PREF}Google Analytics tracker is ready. Client id: ${defaultTracker}`
      );
      persistence.setClientId(defaultTracker);

      queue.empty();
    });
  } else {
    console.warn(`${LOG_PREF}No tracking id configured, so module is disabled`);
  }
}

export const CATEGORIES = {
  intersticiel: 'intersticiel',
  linkClicked: 'linkClicked',
  mobigeo: 'mobigeo',
  appointment: 'appointment',
  note: 'note',
  ad: 'ad',
  contacts: 'contacts',
};

export const ACTIONS = {
  [CATEGORIES.intersticiel]: {
    view: 'view',
  },
  [CATEGORIES.linkClicked]: {
    url: 'url',
  },
  [CATEGORIES.mobigeo]: {
    goTo: 'goTo',
    error: 'error',
    enterSite: 'enterSite',
    setPseudo: 'setPseudo',
    createGroup: 'createGroup',
    joinGroup: 'joinGroup',
    quitGroup: 'quitGroup',
    favoriteCreated: 'favoriteCreated',
    favoriteShared: 'favoriteShared',
  },
  [CATEGORIES.appointment]: {
    success: 'success',
    failure: 'failure',
  },
  [CATEGORIES.note]: {
    noteCreated: 'noteCreated',
    noteDeleted: 'noteDeleted',
  },
  [CATEGORIES.ad]: {
    display: 'display',
    click: 'click',
  },
  [CATEGORIES.contacts]: {
    userScan: 'userScan',
  },
};

const HIT_TYPES = {
  pageview: 'pageview',
  event: 'event',
  social: 'social',
  timing: 'timing',
};

export function sendPageView(page) {
  queue.add({
    hitType: HIT_TYPES.pageview,
    action: 'page_view',
    page_path: page,
    //send_to: GOOGLE_ANALYTICS_ID,
    //page_title: title,
    //page_location: location,
  });
}

export function sendEvent(category, action, label, value) {
  queue.add({
    hitType: HIT_TYPES.event,
    event_category: category,
    action: action,
    event_label: label,
    value: value,
    // 'non_interaction': true
  });
}

export function sendTiming(timingCategory, timingVar, timingValue, timingLabel) {
  queue.add({
    name: timingVar,
    value: timingValue,
    event_category: timingCategory,
    event_label: timingLabel,
    action: 'timing_complete',
  });
}
