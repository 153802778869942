// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dropdown from 'react-dropdown';

import { get as getLabels } from 'src/core/Lang';
import CTAButton from 'src/components/cta-button/CTAButton';

import { VALID_DATA_TYPES } from 'app-customs/config/dataConfig';

// Config
import { HELPERS_PAGE_KEY } from 'src/pages/pagesKeys';
// App modules
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';

import fetchHelper from 'src/core/util/FetchHelper';
import config from 'app-customs/config/config';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

import * as actions from 'src/store/actions';

import 'react-dropdown/style.css';
import './HelpersPage.scss';

const LOG_PREF = '[HelpersPage] ';
export const COMPONENT_KEY = 'helpers';

class HelpersPage extends Component {
  constructor(props) {
    super(props);
    this.pageKey = HELPERS_PAGE_KEY;
    this.state = {
      choosedDataType: null,
    };
    this._onSelectDataType = this._onSelectDataType.bind(this);
  }

  /**
   * String displayed in app toolbar
   * @return {string}
   */
  getPageTitle() {
    return getLabels() && getLabels().helpers && getLabels().helpers.update
      ? getLabels().helpers.update.title
      : '';
  }

  capitalize = (s) => (s && s[0]?.toUpperCase() + s?.slice(1)) || '';

  _onSelectDataType(data) {
    this.setState({ choosedDataType: data.value });
  }

  _updateDataType(deleteUpdate) {
    const datatype = this.capitalize(this.state.choosedDataType);
    const verbBackend = deleteUpdate ? 'delstaticupdate' : 'staticupdate';
    const params = { datatype: datatype };
    const formData = new FormData();
    for (const key in params) {
      if (params.hasOwnProperty(key) === true) {
        formData.append(key, params[key]);
      }
    }
    console.info(LOG_PREF, 'update requested datatype: ', datatype, 'verb :', verbBackend);
    fetchHelper(
      // url:
      `https://${config.BO_URL}/IncrementalUpdate/${verbBackend}`,
      // options:
      { body: formData, method: 'POST' },
      // is json:
      true,
      // on success:
      (datas) => {
        console.info(`${LOG_PREF}update response:`, datas);
        this.props.actions.showNotification({
          message: getLabels().helpers.update.updateSuccess,
          level: NotificationLevels.SUCCESS,
        });
      },
      // on failure:
      (...args) => {
        console.error(`${LOG_PREF}IncrementalUpdate error`, args);
        this.props.actions.showNotification({
          message: getLabels().common.fetchError,
          level: NotificationLevels.ERROR,
        });
      },
      // No modal on error
      false
    );
  }

  _options() {
    return VALID_DATA_TYPES.filter((dataType) => !dataType.includes('_categories'));
  }

  render() {
    console.log(LOG_PREF + 'render');
    if (!this.props.isVisible) return null;
    return (
      <>
        <Menu
          options={this.props.menuOptions}
          actions={this.props.actions}
          labels={this.props.labels}
          profile={this.props.profile}
          associatedPageKey={HELPERS_PAGE_KEY}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          actions={this.props.actions}
          title={this.getPageTitle()}
          pageKey={HELPERS_PAGE_KEY}
          profile={this.props.profile}
          hasBackButton={this.props.backButtonInToolbar}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={this.props.searchButtonInToolbar}
          hasMenuButton={this.props.menuButtonInToolbar}
        />

        <div className="helpers-container">
          <div className="helpers-description">
            <h3 className="helpers-description-title title-font">
              {this.getPageTitle() && getLabels().helpers.update.titleChooseDataType
                ? getLabels().helpers.update.titleChooseDataType
                : ''}
            </h3>
            <Dropdown
              options={this._options() || []}
              onChange={(value) => this._onSelectDataType(value)}
              value={this.state.choosedDataType}
              placeholder={
                this.getPageTitle() && getLabels().helpers.update.placeHolderChooseDataType
                  ? getLabels().helpers.update.placeHolderChooseDataType
                  : ''
              }
            />
            <div className="btn-container">
              <CTAButton
                className="btn"
                action={() => {
                  this._updateDataType();
                }}
                label={
                  this.getPageTitle() && getLabels().helpers.update.titleUpdateBtn
                    ? getLabels().helpers.update.titleUpdateBtn
                    : ''
                }
              />

              <CTAButton
                className="btn"
                action={() => {
                  this._updateDataType(true);
                }}
                label={
                  this.getPageTitle() && getLabels().helpers.update.titleDeleteBtn
                    ? getLabels().helpers.update.titleDeleteBtn
                    : ''
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

HelpersPage.propTypes = {
  // Common page props
  menuOptions: PropTypes.object,
  labels: PropTypes.object,
  actions: PropTypes.object,
  // toolbar
  hasToolbar: PropTypes.bool,
  homeButtonInToolbar: PropTypes.bool,
  backButtonInToolbar: PropTypes.bool,
  menuButtonInToolbar: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[HELPERS_PAGE_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });
export default connect(mapStateToProps, mapDispatchToProps)(HelpersPage);
